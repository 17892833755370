<template>
  <base-view>
    <view-header title="Forms" image="about.jpg">
      What form are you looking for?
    </view-header>
    <view-section>
      <form-group title="Main Forms" :forms="formGroups['Main Forms']" />
      <form-group title="Plan Guides" :forms="formGroups['Plan Guides']" />
      <form-group title="New HSA Forms" :forms="formGroups['New HSA Forms']" />
      <form-group
        title="Español / Spanish"
        :forms="formGroups['Español / Spanish']"
        :startOpen="false"
      />
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSection from "@/components/layout/ViewSection.vue";
import FormGroup from "./components/FormGroup.vue";

export default {
  components: {
    BaseView,
    ViewHeader,
    ViewSection,
    FormGroup
  },
  data() {
    return {
      formGroups: {
        "New HSA Forms": [
          "Consolidate UMB HSAs - Elevate to Elevate Form.pdf",
          "Consolidate HSAs - External to Elevate UMB Form.pdf",
          "Death Distribution UMB.pdf",
          "Elevate HSA Mistaken Distribution Form.pdf",
          "Elevate HSA Rollover Form.pdf",
          "HSA Closure Form.pdf",
          "HSA Transfer Divorce UMB.pdf",
          "HSA Withdrawal Request Form.pdf",
          "Interest Waiver UMB.pdf",
          "Name Change UMB.pdf",
          "Power of Attorney UMB.pdf",
          "UMB Beneficiary Form.pdf",
          "Address Change Request UMB.pdf"
        ],
        "Main Forms": [
          "RMR Claim Form.pdf",
          "Debit Card Request Form.pdf",
          "EAP Application.pdf",
          "FSA Expense Planning Worksheet.pdf",
          "Direct Deposit Form.pdf",
          "Letter of Medical Necessity.pdf",
          "RMR Enrollment Form.pdf",
          "LPF to FSA Conversion.pdf",
          "Employee Guide to COBRA.pdf",
          "Mileage Worksheet.xlsx",
          "RMR ACH Authorization Form.pdf",
          "Disputed Point of Sale Transactions Form.pdf",
          "HSA ONLY Enrollment Form.pdf"
        ],
        "Plan Guides": [
          "RMR FSA Enrollment Guide.pdf",
          "RMR DCA Enrollment Guide.pdf",
          "RMR PKG Enrollment Guide.pdf",
          "RMR TRN Enrollment Guide.pdf",
          "RMR HSA Enrollment Guide.pdf",
          "RMR Limited FSA Enrollment Guide.pdf",
          "RMR ICHRA Enrollment Guide.pdf",
          "RMR Tuition Reimbursement Enrollment Guide.pdf",
          "RMR FSA No LPF Enrollment Guide.pdf",
          "RMR LSA Enrollment Guide.pdf",
          "RMR HRA Enrollment Guide.pdf",
          "RMR HRA Enrollment Guide No Debit Card.pdf"
        ],
        // UMB: [
        //   "UMB-RMR Trustee Transfer Form.pdf",
        //   "UMB HSA Beneficiary Form.pdf",
        //   "UMB Account Closure Form.pdf",
        //   "UMB Contribution and Mistaken Distribution Reversal Form.pdf",
        //   "UMB Contribution Reversal Form.pdf",
        //   "UMB HSA Withdrawal Form.pdf",
        //   "UMB HSA Death Distribution Form.pdf",
        //   "UMB Dormant Account Reactivation Form.pdf",
        //   "UMB 2020 Health Disclosures.pdf"
        // ],
        // "WealthCare Saver HSA": [
        //   "WealthCare Saver HSA Account Closure Form.pdf",
        //   "WealthCare Saver HSA Account Holder Contribution Correction Form.pdf",
        //   "WealthCare Saver HSA ACH Dispute Form.pdf",
        //   "WealthCare Saver HSA Authorized User Form.pdf",
        //   "WealthCare Saver HSA Beneficiary Form.pdf",
        //   "WealthCare Saver HSA Change Power of Attorney Form.pdf",
        //   "WealthCare Saver HSA Consent to Electronic Communications.pdf",
        //   "WealthCare Saver HSA Contribution Form.pdf",
        //   "WealthCare Saver HSA Death Distribution Form.pdf",
        //   "WealthCare Saver HSA Distribution of Excess or Mistaken Contribution Form.pdf",
        //   "WealthCare Saver HSA Employer Contribution Error Form.pdf",
        //   "WealthCare Saver HSA Fee Schedule.pdf",
        //   "WealthCare Saver HSA Interest Rate Disclosure.pdf",
        //   "WealthCare Saver HSA Name Change Request Form.pdf",
        //   "WealthCare Saver HSA Privacy Policy.pdf",
        //   "WealthCare Saver HSA Program Custodial Agreement.pdf",
        //   "WealthCare Saver HSA Rollover Distribution Form.pdf",
        //   "WealthCare Saver HSA Text Messaging Registration.pdf",
        //   "WealthCare Saver HSA Transfer Due to Divorce Form.pdf",
        //   "WealthCare Saver HSA Transfer of Assets Form.pdf",
        //   "WealthCare Saver HSA Welcome Guide.pdf",
        //   "WealthCare Saver HSA Withdrawal Form.pdf",
        //   "WealthCare Saver HSA Investments Quick Start Guide.pdf",
        //   "WealthCare Saver HSA Investments User Guide.pdf"
        // ],
        "Español / Spanish": [
          "RMR Formulario de Reclamación.pdf",
          "RMR Formulario de Inscripción.pdf",
          "Formulario de Inscripción (HSA).pdf",
          "Formulario para Depósito Directo.pdf",
          "RMR FSA Guía de Inscripcion.pdf",
          "RMR HSA Guía de Inscripcion.pdf",
          "RMR PKG & TRN Guía de Inscripcion.pdf",
          "RMR Solicitud de Tarjeta de Débito Adicional.pdf",
          "RMR Formulario de Autorización ACH.pdf"
        ]
      }
    };
  }
};
</script>

<style scoped>
.form-link:nth-last-of-type(2):nth-child(odd),
.form-link:last-of-type {
  border-bottom: 0;
}

/* .form-link:nth-of-type(4n),
.form-link:nth-of-type(4n-1) {
  background: theme("colors.neutral.50");
}

.form-link:nth-of-type(4n):hover,
.form-link:nth-of-type(4n-1):hover {
  background: theme("colors.neutral.100");
} */
</style>
