<template>
  <div class="bg-neutral-lighter rounded-lg shadow mb-6 overflow-hidden p-6">
    <div
      class="flex flex-row cursor-pointer items-center"
      @click="showForms = !showForms"
    >
      <h3 class="text-2xl leading-6 font-semibold text-primary flex-1">
        {{ title }}
      </h3>
      <span :class="icon" />
    </div>
    <transition @enter="enter" @after-enter="afterEnter" @leave="leave">
      <div class="flex flex-col md:flex-row" v-show="showForms">
        <div class="divide-y md:w-1/2 md:pr-4 mt-4 border-b md:border-b-0">
          <form-group-list :fileList="firstHalf" />
        </div>
        <div class="divide-y md:w-1/2 md:pl-4 md:mt-4">
          <form-group-list :fileList="secondHalf" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FormGroupList from "./FormGroupList.vue";

export default {
  components: {
    FormGroupList
  },
  data() {
    return {
      showForms: this.startOpen
    };
  },
  props: {
    title: {
      type: String,
      default: "Forms"
    },
    forms: {
      type: Array,
      default: () => {
        return [];
      }
    },
    startOpen: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    firstHalf() {
      return this.forms.slice(0, Math.ceil(this.forms.length / 2));
    },
    secondHalf() {
      return this.forms.slice(Math.ceil(this.forms.length / 2));
    },
    icon: function() {
      if (this.showForms) {
        return "flaticon-minus";
      } else {
        return "flaticon-plus";
      }
    }
  },
  methods: {
    fileName(file) {
      return file.split(".")[0];
    },
    fileFormat(file) {
      return file.split(".").at(-1);
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      var height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      //Calculate transition time based on height
      let duration = (Math.floor(parseInt(height) / 100) + 1) * 0.2;

      // Trigger the animation.
      // We use `setTimeout` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      setTimeout(() => {
        element.style.transition = `height ${duration}s ease-in-out`;
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
      // element.style.padding = "1rem 1rem";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      //Calculate transition time based on height
      let duration = Math.floor(parseInt(height) / 100) * 0.15;

      setTimeout(() => {
        element.style.transition = `height ${duration}s ease-in-out`;
        element.style.height = 0;
      });
    }
  }
};
</script>
