<template>
  <div class="divide-y">
    <div
      v-for="file in fileList"
      :key="file"
      class="flex flex-row items-center text-base py-2"
    >
      <div class="w-5 mr-4">
        <svg
          v-if="fileFormat(file) === 'xlsx'"
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="10 7 27 35"
          x="0px"
          y="0px"
        >
          <path
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            d="M28.27,13.68V8H13a2,2,0,0,0-2,2V39a2,2,0,0,0,2,2H34a2,2,0,0,0,2-2V15.68H30.27A2,2,0,0,1,28.27,13.68Z"
          />
          <path
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            d="M28.27,8v5.68a2,2,0,0,0,2,2H36L28.3,8Z"
          />
          <line
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            x1="16.37"
            y1="23.89"
            x2="30.39"
            y2="23.89"
          />
          <line
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            x1="16.37"
            y1="28.39"
            x2="30.39"
            y2="28.39"
          />
          <line
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            x1="16.37"
            y1="32.89"
            x2="30.39"
            y2="32.89"
          />
          <line
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            x1="16.37"
            y1="23.89"
            x2="16.37"
            y2="32.89"
          />
          <line
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            x1="23.38"
            y1="24"
            x2="23.38"
            y2="32.89"
          />
          <line
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            x1="30.39"
            y1="23.89"
            x2="30.39"
            y2="32.89"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="10 7 27 35"
          x="0px"
          y="0px"
        >
          <path
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            d="M28.27,13.68V8H13a2,2,0,0,0-2,2V39a2,2,0,0,0,2,2H34a2,2,0,0,0,2-2V15.68H30.27A2,2,0,0,1,28.27,13.68Z"
          />
          <path
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            d="M28.27,8v5.68a2,2,0,0,0,2,2H36L28.3,8Z"
          />
          <line
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            x1="16.37"
            y1="23.89"
            x2="30.39"
            y2="23.89"
          />
          <line
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            x1="16.37"
            y1="28.39"
            x2="30.39"
            y2="28.39"
          />
          <line
            class="stroke-current"
            style="fill: none; stroke-linecap: round; stroke-linejoin: round;"
            x1="16.37"
            y1="32.89"
            x2="30.39"
            y2="32.89"
          />
        </svg>
      </div>
      <div class="flex-1">
        {{ fileName(file) }}
      </div>
      <a
        :href="file"
        download
        class="bg-primary-light hover:bg-primary-mid active:bg-primary rounded text-white px-2 py-1 flex flex-row"
        >Download <span class="ml-2 flaticon-down"></span
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fileList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    fileName(file) {
      return file.split(".")[0];
    },
    fileFormat(file) {
      return file.split(".").at(-1);
    }
  }
};
</script>
